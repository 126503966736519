import React from 'react'

import HomeDefense from '../../assets/homedefense/logo_white.svg'

import css from './PageLoader.module.scss'

const PageLoader = () => (
  <div className={css.LoaderBkg}>
    <img src={HomeDefense} alt='Home Defense' className={css.Logo} />
  </div>
)

export default PageLoader
